export function isRecord<T = unknown>(
  data: unknown,
): data is Record<string, T> {
  return data !== null && typeof data === 'object' && !Array.isArray(data);
}

/**
 * Validates if the object has all the specified keys.
 *
 * @param {any} obj - The object to validate.
 * @param {Array<keyof T>} keys - The keys to check in the object.
 * @return {boolean} Returns true if the object has all the specified keys, false otherwise.
 * @example validateType({ name: 'John', age: 30 }, ['name', 'age']); // true
 * validateType({ name: 'John', age: 30 }, ['name', 'address']); // false
 */
export function validateType<T>(obj: unknown, keys: (keyof T)[]): obj is T {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  return keys.every((key) => key in obj);
}
