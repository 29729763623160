import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';

import {
  KeyboardShortcutContext,
  KeyboardShortcutRegistration,
} from './KeyboardShortcutContext';

export const KeyboardShortcutProvider = ({ children }: PropsWithChildren) => {
  const callbackRef = useRef<Set<KeyboardShortcutRegistration>>(new Set());

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const key = event.key.toLowerCase();

    for (const {
      callback,
      targetKey,
      ignoreInputs = true,
      enabled = true,
    } of callbackRef.current) {
      if (!enabled) {
        continue;
      }

      if (key !== targetKey.toLowerCase()) {
        continue;
      }

      if (
        ignoreInputs &&
        (event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLTextAreaElement)
      ) {
        continue;
      }

      callback(event);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const register = useCallback((shortcut: KeyboardShortcutRegistration) => {
    callbackRef.current.add(shortcut);

    return {
      unregister: () => {
        callbackRef.current.delete(shortcut);
      },
    };
  }, []);

  const contextValue = useRef({ register });

  return (
    <KeyboardShortcutContext.Provider value={contextValue.current}>
      {children}
    </KeyboardShortcutContext.Provider>
  );
};
