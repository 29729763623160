import { useAuth0 } from '@auth0/auth0-react';
import { STYLUX_API_GRAPHQL_ENDPOINT } from '@constants/stylux-endpoints';
import {
  type IFieldsOrResolvers,
  mutationToGetManyConfig,
} from '@services/graphql/cacheInvalidationConfig';
import { authExchange } from '@urql/exchange-auth';
import {
  Cache,
  cacheExchange,
  UpdateResolver,
} from '@urql/exchange-graphcache';
import { persistedExchange } from '@urql/exchange-persisted';
import { isApiAuthError } from '@utils';
import { useMemo } from 'react';
import { createClient, fetchExchange, type Client } from 'urql';

function invalidateCacheByFieldName({
  cache,
  fieldNameOrNames,
}: {
  cache: Cache;
  fieldNameOrNames: string | string[];
}) {
  const fieldSet = Array.isArray(fieldNameOrNames)
    ? new Set(fieldNameOrNames)
    : new Set([fieldNameOrNames]);

  cache
    .inspectFields('Query')
    .filter((field) => fieldSet.has(field.fieldName))
    .forEach((field) => {
      cache.invalidate('Query', field.fieldKey);
    });
}

function buildMutationUpdateResolver(
  config: Record<string, IFieldsOrResolvers>,
): {
  [fieldName: string]: UpdateResolver | void;
} {
  const resolver: { [fieldName: string]: UpdateResolver | void } = {};

  for (const [mutationName, _fieldsOrResolvers] of Object.entries(config)) {
    const fieldsOrResolvers = Array.isArray(_fieldsOrResolvers)
      ? _fieldsOrResolvers
      : [_fieldsOrResolvers];

    const fieldNameOrNames = fieldsOrResolvers.filter(
      (value) => typeof value === 'string',
    );

    const updateResolvers = fieldsOrResolvers.filter(
      (value) => typeof value !== 'string',
    );

    resolver[mutationName] = (parent, args, cache, info) => {
      updateResolvers.forEach((resolver) =>
        resolver(parent, args, cache, info),
      );

      invalidateCacheByFieldName({
        cache,
        fieldNameOrNames,
      });
    };
  }

  return resolver;
}

export function useClient() {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo<Client>(() => {
    const client = createClient({
      url: STYLUX_API_GRAPHQL_ENDPOINT,

      exchanges: [
        cacheExchange({
          updates: {
            Mutation: buildMutationUpdateResolver(mutationToGetManyConfig),
          },
        }),

        authExchange(async (utils) => {
          let accessToken = await getAccessTokenSilently();

          return {
            addAuthToOperation(operation) {
              return utils.appendHeaders(operation, {
                authorization: `Bearer ${accessToken}`,
              });
            },

            didAuthError(error) {
              return isApiAuthError(error);
            },

            async refreshAuth() {
              accessToken = await getAccessTokenSilently();
            },
          };
        }),

        persistedExchange(),
        fetchExchange,
      ],
    });

    return client;
  }, [getAccessTokenSilently]);
}
