import { createContext } from 'react';

export type KeyboardShortcutCallback = (event: KeyboardEvent) => void;

export type KeyboardShortcutRegistration = {
  targetKey: string;
  callback: KeyboardShortcutCallback;
  ignoreInputs?: boolean;
  enabled?: boolean;
};

type KeyboardShortcutContextType = {
  register: (shortcut: KeyboardShortcutRegistration) => {
    unregister: () => void;
  };
};

export const KeyboardShortcutContext =
  createContext<KeyboardShortcutContextType | null>(null);
