import { createContext } from 'react';
import { Location } from 'react-router-dom';

export type PreviousLocationContextType = {
  previousLocation: Location<unknown> | null;
};

const context = createContext<PreviousLocationContextType | null>(null);

export { context as PreviousLocationContext };
