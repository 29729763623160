import { ReactNode, useEffect, useState } from 'react';

export function FlowbiteWrapper({ children }: { children: ReactNode }) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    import('flowbite').then((flowbite) => {
      flowbite.initFlowbite();
    });
  }, []);

  return isMounted ? children : null;
}
