import { User as Auth0User } from '@auth0/auth0-react';

export class User {
  name: string | null = null;
  userProfile: Auth0User | null = null;
  accessToken: null | string = null;

  constructor({
    name = null,
    userProfile = null,
  }: {
    accessToken?: string | null;
    name?: string | null;
    userProfile?: Auth0User | null;
  } = {}) {
    this.name = name;
    this.userProfile = userProfile;
  }
}
