import { UserContext } from '@contexts/User/UserContext';
import { useContext } from 'react';

export function useUser() {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error(
      '`useUser` can only be used if your app has been wrapped in `UserContext.Provider`',
    );
  }

  return user;
}
