import { BellIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';

export type NavigationItem = {
  name?: string;
  component?: ReactNode;
  href?: string;
  onClick?: () => void;
  icon?: ReactNode;
};

// TODO: add user navigation
export const userNavigation: NavigationItem[] = [
  {
    name: 'Merchant settings',
    href: '#',
    icon: <Cog6ToothIcon />,
  },
  {
    name: 'Alerts',
    href: '#',
    icon: <BellIcon />,
  },
  {
    name: 'My settings',
    href: '#',
  },
];

export const mainNavigation: NavigationItem[] = [
  {
    name: 'Dashboard',
    href: '/',
  },
  {
    name: 'Orders',
    href: '/orders',
  },
  {
    name: 'Inventory',
    href: '/inventory',
  },
  {
    name: 'Offers',
    href: '/offers',
  },
  {
    name: 'Products',
    href: '/products',
  },
];
