import { PropsWithChildren } from 'react';
import { FlowbiteWrapper } from './FlowbiteWrapper';
import { ToastNotification } from './ToastNotification';

export function ThemeProvider({ children }: PropsWithChildren) {
  return (
    <FlowbiteWrapper>
      <ToastNotification />
      {children}
    </FlowbiteWrapper>
  );
}
