import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = ({ className = '' }: { className?: string } = {}) => {
  const { logout } = useAuth0();

  return (
    <button
      className={className}
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Sign out
    </button>
  );
};

export default LogoutButton;
