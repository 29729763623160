import { withAuthenticationRequired } from '@auth0/auth0-react';
import { KeyboardShortcutProvider } from '@contexts/KeyboardShortcut/KeyboardShortcutProvider';
import { PreviousLocationProvider } from '@contexts/PreviousLocation/PreviousLocationProvider';
import { Outlet } from 'react-router-dom';
import { TopBarNavigationLayout } from './components/layouts/TopBarNavigationLayout';
import { ThemeProvider } from './contexts/ThemeProvider';
import { UrqlClientProvider } from './contexts/UrqlClientProvider';
import { PortalUserProvider } from './contexts/User/PortalUserProvider';

function InternalLayout() {
  return (
    <TopBarNavigationLayout>
      <Outlet />
    </TopBarNavigationLayout>
  );
}

const InternalLayoutWithAuth = withAuthenticationRequired(InternalLayout);

const App = () => {
  return (
    <PortalUserProvider>
      <PreviousLocationProvider>
        <UrqlClientProvider>
          <ThemeProvider>
            <KeyboardShortcutProvider>
              <InternalLayoutWithAuth />
            </KeyboardShortcutProvider>
          </ThemeProvider>
        </UrqlClientProvider>
      </PreviousLocationProvider>
    </PortalUserProvider>
  );
};

export default App;
