import { useMediaQuery } from '../hooks/useMediaQuery';
import clsx from 'clsx';
import { ReactElement, ReactNode, cloneElement } from 'react';

/**
 * Clones a React element and adds the ability to add additional classNames.
 *
 * @param element The React element to clone.
 * @param  className class names to add.
 * @returns A new React element with the additional class names.
 */
export function cloneElementWithClass(
  element: ReactNode,
  className: string,
): ReactNode {
  return element
    ? cloneElement(element as ReactElement, {
        className: clsx(className, (element as ReactElement).props.className),
      })
    : null;
}

export const useIsMobile = () => {
  return useMediaQuery('(max-width: 639px)');
};
