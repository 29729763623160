import { CombinedError } from 'urql';

export function isApiAuthError(error: CombinedError) {
  return error.graphQLErrors.some(
    (e) =>
      e.extensions?.['code'] === 'FORBIDDEN' ||
      e.extensions?.['code'] === 'UNAUTHORIZED' ||
      e.extensions?.['code'] === 'UNAUTHORIZED_EXCEPTION',
  );
}
