import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import { User } from './user';

function InternalPortalUserProvider({ children }: PropsWithChildren) {
  const { user: auth0User, isLoading } = useAuth0();

  const user = useMemo(() => new User(), []);

  useEffect(() => {
    user.name = auth0User?.name ?? user.name ?? null;
    user.userProfile = auth0User ?? user.userProfile ?? null;
  }, [user, auth0User]);

  if (isLoading) {
    return <></>;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function PortalUserProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    const { pathname } = window.location;
    const returnToOrPathName = appState?.returnTo ?? pathname;

    if (returnToOrPathName === '/logout') {
      return navigate('/');
    }

    return navigate(returnToOrPathName);
  };

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <InternalPortalUserProvider>{children}</InternalPortalUserProvider>
    </Auth0Provider>
  );
}
