import { cloneElementWithClass } from '@utils';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip';

type Props = {
  children: ReactNode;
  href?: string;
  asButton?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  disabledTooltip?: TooltipProps;
  /**
   * Based on Tailwind font size
   * @see https://tailwindcss.com/docs/font-size
   */
  fontSize?: string;
  /**
   * Based on Tailwind text color class
   * @see https://tailwindcss.com/docs/text-color
   */
  textColor?: string;
};

export const Link = ({
  href,
  children,
  asButton,
  onClick,
  icon,
  disabled,
  disabledTooltip,
  fontSize,
  textColor,
}: Props) => {
  if (asButton) {
    if (!onClick) {
      throw new Error('Link: onClick is required when asButton is true');
    }
    if (href) {
      throw new Error(
        'Link: href has no effect when asButton is true, use onClick instead',
      );
    }
  }

  if (disabled && !disabledTooltip) {
    throw new Error('Link: disabledTooltip is required when disabled');
  }

  const Icon = icon ? cloneElementWithClass(icon, 'w-5 h-5') : null;

  const content = (
    <div
      className={clsx(
        'flex items-center gap-2',
        !disabled && 'hover:opacity-80',
        fontSize,
      )}
    >
      {asButton ? (
        <button
          onClick={(event) => {
            event.preventDefault();
            onClick?.();
          }}
          disabled={disabled}
          className={clsx(
            'font-semibold underline focus:outline-none focus:ring-2 focus:ring-product-dark focus:ring-offset-2 focus-visible:ring-product-dark disabled:text-gray-500',
            textColor,
          )}
        >
          {children}
        </button>
      ) : (
        <ReactRouterLink to={href ?? '#'} className={textColor}>
          {children}
        </ReactRouterLink>
      )}
      {Icon}
    </div>
  );

  if (disabled && disabledTooltip) {
    return <Tooltip {...disabledTooltip}>{content}</Tooltip>;
  }

  return content;
};
