import { useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean | null {
  const [matches, setMatches] = useState<boolean | null>(null);

  useEffect(() => {
    const mediaQueryList: MediaQueryList = window.matchMedia(query);

    const handleMatchChange = (e: MediaQueryListEvent): void => {
      setMatches(e.matches);
    };

    mediaQueryList.addEventListener('change', handleMatchChange);

    setMatches(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', handleMatchChange);
    };
  }, [query]);

  return matches;
}
