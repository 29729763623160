import { cloneElementWithClass } from '@utils';
import { ReactNode } from 'react';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip';

type Props = {
  onClick: () => void;
  icon: ReactNode;
  hoverTooltip?: TooltipProps;
};

export const IconButton = ({ onClick, icon, hoverTooltip }: Props) => {
  const Icon = icon ? cloneElementWithClass(icon, 'h-6 w-6') : null;

  const iconButton = (
    <button
      type="button"
      onClick={onClick}
      className="relative rounded-full bg-transparent text-gray-400 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-product-dark focus-visible:ring-offset-2"
    >
      {Icon}
    </button>
  );

  if (hoverTooltip) {
    return <Tooltip {...hoverTooltip}>{iconButton}</Tooltip>;
  }

  return iconButton;
};
