import clsx from 'clsx';
import type { TooltipOptions } from 'flowbite';
import { Tooltip as FlowbiteTooltip } from 'flowbite';
import { ReactNode, useEffect, useRef } from 'react';

export type TooltipProps = {
  title: string | ReactNode | null;
  content?: string | ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  variant?: 'light' | 'dark';
};

type Props = {
  title: TooltipProps['title'] | null;
  content?: TooltipProps['content'];
  position?: TooltipProps['position'];
  variant?: TooltipProps['variant'];
  triggerType?: 'hover' | 'click';
  children: ReactNode;
  /**
   * Based on Tailwind className
   * @see https://tailwindcss.com/docs/width
   */
  width?: string;
  centerText?: boolean;
};

export default function Tooltip({
  children,
  content,
  title,
  position = 'top',
  variant = 'dark',
  triggerType = 'hover',
  width,
  centerText,
}: Props) {
  if (!title && !content) {
    throw new Error('Tooltip: requires a title or content');
  }

  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (triggerRef.current && tooltipRef.current) {
      const options: TooltipOptions = {
        placement: position,
        triggerType: triggerType,
      };

      const newTooltip = new FlowbiteTooltip(
        tooltipRef.current,
        triggerRef.current,
        options,
      );

      return () => {
        newTooltip.destroy();
      };
    }

    return () => {};
  }, [position, triggerType]);

  return (
    <>
      <div ref={triggerRef} className="inline">
        {children}
      </div>
      <div
        ref={tooltipRef}
        role="tooltip"
        className={clsx(
          'tooltip invisible absolute z-10 inline-block w-max max-w-xs rounded-lg px-3 py-2 text-sm font-medium text-white opacity-0 shadow-lg transition-opacity duration-300',
          variant === 'dark' ? 'bg-gray-800' : 'bg-white',
          width,
          centerText && 'text-center',
        )}
      >
        {title && (
          <div
            className={clsx(
              'font-medium',
              variant === 'dark' ? 'text-white' : 'text-gray-900',
              content && 'mb-1',
            )}
          >
            {title}
          </div>
        )}
        {content && (
          <div
            className={clsx(
              variant === 'dark' ? 'text-gray-400' : 'text-gray-500',
            )}
          >
            {content}
          </div>
        )}
        <div className="tooltip-arrow" data-popper-arrow></div>
      </div>
    </>
  );
}
