import { PropsWithChildren, useEffect, useRef } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { PreviousLocationContext } from './PreviousLocationContext';

export function PreviousLocationProvider({ children }: PropsWithChildren) {
  const previousLocation = useLocation();
  const previousLocationRef = useRef<Location<unknown> | null>(null);

  useEffect(() => {
    previousLocationRef.current = previousLocation;
  }, [previousLocation]);

  return (
    <PreviousLocationContext.Provider
      value={{ previousLocation: previousLocationRef.current }}
    >
      {children}
    </PreviousLocationContext.Provider>
  );
}
